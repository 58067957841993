.ExcelTable2007 {
  border: 1px solid #ffffff;
  border-width: 1px 0px 0px 1px;
  font-size: 10px;
  font-family: arial;
  font-weight: 100;
  border-spacing: 0px;
  border-collapse: collapse;
}

.ExcelTable2007 th {
  /*background-image: url(excel-2007-header-bg.gif);*/
  background-repeat: repeat-x;
  font-weight: normal;
  font-size: 12px;
  border: 1px solid #ffffff;
  border-width: 0px 1px 1px 0px;
  height: 17px;
  text-align: center;
  color: white;
}

.ExcelTable2007 td {
  border: 0px;
  width: 20px;
  background-color: white;
  padding: 0px 4px 0px 2px;
  border: 1px solid #d0d7e5;
  border-width: 0px 1px 1px 0px;
  border: 1px solid #ffffff;
  text-align: center;
}

.ExcelTable2007 td b {
  border: 0px;
  background-color: rgb(133, 101, 101);
  font-weight: bold;
  border: 1px solid #ffffff;
  color: white;
}

.ExcelTable2007 td.heading {
  background-color: #ffffff;
  text-align: center;
  border: 1px solid #ffffff;
  border-width: 0px 1px 1px 0px;
  color: white;
}

.ExcelTable2007 th.heading {
  /*background-image: url(excel-2007-header-left.gif);*/
  background-repeat: none;
  border: 1px solid #ffffff;
}

.inputTariff {
  background-color: #395181;
  width: 250px;
  border-radius: 10px;
  color: #ffffff;
  font-family: arial;
  font-size: 12px;
}
