div#dashboard-container-header {
  padding: 0.5em;
  font-size: 17px;
  color: dodgerblue;
  display: flex;
  border-bottom-style: none;
  border-bottom-color: darkgrey;
  height: 2em;
  font-style: italic;
  font-weight: bold;
}
#graph-area {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 250px;
  position: relative;
  left: 0px;
}
#graph-area2 {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 80px;
  /*position: relative;
  left: 0px;*/
}
#graph-area3 {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 150px;
  /*position: relative;
  left: 0px;*/
}
#walkin-graph-area {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 250px;
  position: relative;
  left: 0px;
}

#walkin-graph-area2 {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 80px;
  /*position: relative;
  left: 0px;*/
}

#thermostat-chart {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 250px;
  position: relative;
  left: 0px;
}
#walkin-chart {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 250px;
  position: relative;
  left: 0px;
}
#onTime-chart {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 90px;
  /*position: relative;
  left: 0px;*/
}
#openDoor-chart {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 90px;
  /*position: relative;
  left: 0px;*/
}

#bldgPower-chart {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 250px;
  position: relative;
  left: 0px;
}
#graph-area-interval {
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  max-width: 100%; /*80%*/
  height: 350px;
  margin-left: 10px; /*150px*/
}
#demand-graph {
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  max-width: 100%; /*80%*/
  height: 350px;
  margin-left: 10px; /*150px*/
}

#graph-area-interval2 {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 80%;
  height: 300px;
  margin-left: 150px;
}

#money-graph {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%; /*80%*/
  height: 350px;
  margin-left: 10px; /*150px*/
  margin-bottom: 200px;
}

#gauge-area-1 {
  border-color: #000000;
  border-style: none;
  border-width: 0px;
  /*border-radius: 24px 24px 24px 24px;*/
  /*max-width: 100%;*/
  width: 350px;
  height: 400px;
  margin-left: 50%;
  transform: translateX(-175px);
  box-shadow: 50px 50px 50px 0 rgba(0, 0, 0, 0.2),
    50px 50px 50px 0 rgba(0, 0, 0, 0.2), 50px 50px 50px 0 rgba(0, 0, 0, 0.2),
    50px 50px 50px 0 rgba(15, 15, 15, 0.19);
  /* position: relative;
  left: 50%;
  margin: 0px;
  padding: 0px;*/
  z-index: 1;
}
#gauge-area-2 {
  border-color: #000000;
  border-style: none;
  border-width: 1px;
  /*border-radius: 24px 24px 24px 24px;*/
  /*max-width: 100%;*/
  width: 250px;
  height: 200px;
  margin-left: 50%;
  transform: translate(-127px, 20px);
  /*box-shadow: 50px 50px 50px 0 rgba(0, 0, 0, 0.2),
    50px 50px 50px 0 rgba(0, 0, 0, 0.2), 50px 50px 50px 0 rgba(0, 0, 0, 0.2),
    50px 50px 50px 0 rgba(15, 15, 15, 0.19);*/
  /* position: relative;
  left: 50%;
  margin: 0px;
  padding: 0px;*/
  /*z-index: 1;*/
}
/*#gauge-area-2 {
  border-color: #000000;
  border-style: solid;
  border-width: 2px;
  max-width: 50%;
  height: 200px;
  position: relative;
  left: 0px;
  margin: 0px;
  padding: 0px;
}*/

.iconsPosition {
  position: absolute;
  top: 20px;
  z-index: 10;
}

div#dashboard-container-header-text {
  flex: 0 0 75%;
}

div#dashboard-container-header-icons {
  flex: 1;
  text-align: right;
}

div.dash-container {
  background-color: #ffffff;
  padding-left: 0em;
  padding-right: 0em;
  padding-top: 0em;
  width: 100%;
  left: 0px;
  display: flex;
  float: left;
  position: relative;
  flex-direction: column;
}

div.dash-top-row {
  background-color: #ffffff;
  width: auto;
  display: flex;

  flex: 1 0 auto;
  flex-direction: row;
}
div.dash-graph-box-top {
  background-color: #ffffff;
  max-width: 100%;

  margin: 10px;
  flex: 1 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
div.dash-middle-row {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  height: 30em;
}

div.dash-middle-row-half-left-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 50%;
  max-width: 50%;
  margin: 0px;
  padding-left: 0em;
  padding-right: 0em;
}

div.dash-middle-row-half-right-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 50%;
  max-width: 50%;
  margin: 0px;
  padding-left: 0em;
  padding-right: 0em;
}

div.dash-bottom-row-left-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 33%;
  max-width: 33%;
}

div.dash-bottom-row-middle-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 33%;
  max-width: 34%;
}

div.dash-bottom-row-right-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 33%;
  max-width: 33%;
}

div.dash-history-box {
  background-color: white;
  margin: 10px;
  flex: 1 0 auto;
  height: 30em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div#history-bar-chart {
  width: 100%;
  height: 30em;
}

div#bills-pie-chart {
  width: 100%;
  height: 30em;
}

div.dash-connect-box {
  background-color: white;
  margin: 10px;
  flex: 1 0 auto;
  height: 30em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.dash-simpl-global-box {
  background-color: white;
  margin: 10px;
  flex: 1 0 auto;
  height: 30em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.dash-graph-box-middle {
  background-color: rgba(255, 255, 255, 0.99);
  max-width: 100%;
  margin: 10px;
  flex: 1 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 23em;
}

div.dash-bill-pie-chart-box {
  background-color: white;
  margin: 10px;
  flex: 1 0 auto;
  height: 30em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.dash-devices-box {
  background-color: white;
  margin: 10px;
  flex: 1 0 auto;
  height: 61em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
div#dash-device-row-container {
  padding-left: 15px;
  height: 7em;
  border-bottom-style: solid;
  border-bottom-color: grey;
  display: flex;
  flex-direction: row;
}

div#dash-device-row-logo {
  line-height: 6.5em;
  flex: 1 0 auto;
  min-width: 15%;
  max-width: 15%;
}

div#dash-device-row-id {
  flex: 1 0 auto;
  line-height: 4.9em;
  padding-left: 10px;
  max-height: 25%;
  font-size: 20px;
  color: grey;
}

div#dash-device-row-data {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
}

div#dash-device-column-power {
  flex: 1 0 auto;
  padding-left: 10px;
  min-width: 33%;
  max-width: 34%;
  border-left-style: solid;
  border-left-color: grey;
}

.containerBoxes {
  border-color: black;
  border-style: solid;
  border-width: 2px;
  width: 10%;
}

.containerBoxMesh {
  border-color: #ffffff;
  border-style: none;
  border-width: 2px;
  width: 10%;
  float: right;
  position: relative;
  top: 0px;
}

.containerBoxNumbers {
  /* border-color: #ffffff;
  border-style: none;
  border-width: 2px;*/
  width: 10%;
  float: right;
  position: relative;
  top: -450px;
  right: -150px;
}

.containerGraph {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  width: 100%;
  height: 600px;
  margin: 0;
  padding: 0;
}

.orange-progress-bar {
  background-color: #8a0d4b !important;
}

.progressBar1 {
  width: 480px;
  font-size: 11px;
  font-weight: bold;
  transform: rotate(270deg) translateX(310px) translateY(-220px);
  color: white;
  text-align: center;
  padding-top: 0px;
}
.progressBar2 {
  width: 480px;
  font-size: 11px;
  font-weight: bold;
  transform: rotate(270deg) translateX(325px) translateY(-195px);
  color: white;
  text-align: center;
  padding-left: 0px;

  padding-top: 0px;
}
.progressBar3 {
  width: 480px;
  font-size: 11px;
  font-weight: bold;
  transform: rotate(270deg) translateX(340px) translateY(-165px);
  color: white;
  text-align: center;
  padding-left: 0px;
  padding-top: 0px;
}
.progressBarPowerStack {
  width: 480px;
  font-size: 11px;
  font-weight: bold;
  transform: rotate(270deg) translateX(340px) translateY(-165px);
  color: purple;
  text-align: center;
  padding-left: 0px;
  padding-top: 0px;
}
.systemClock {
  border-radius: 5px;
  width: 310px;
  margin-left: 50%;
  transform: translateX(-155px);
  color: #ffc000;
  background-color: #777575;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.systemClockNode {
  border-radius: 5px;
  width: 1150px;
  margin-left: 25px;
  /*transform: translateX(-30px);*/
  color: #ffc000;
  background-color: #777575;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.containerSimplView {
  /*border-color: #000000;
  border-style: solid;
  border-width: 2px;*/
  width: 325px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}
.containerSimplNodeVu {
  /*border-color: #000000;
  border-style: solid;
  border-width: 2px;*/
  width: 1200px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}
.mainMenuIcons {
  border-color: #f7f1f1;
  background-color: #f7f1f1;
  border-style: solid;
  border-width: 0px;
  border-radius: 5px;
  padding-top: 5px;
  width: 325px;
}
.mainMenuNodesIcons {
  border-color: #f7f1f1;
  background-color: #f7f1f1;
  border-style: solid;
  border-width: 0px;
  border-radius: 5px;
  padding-top: 5px;
  width: 1200px;
}
.mainMenuContainer {
  /*border-color: #ffffff;
  border-style: solid;
  border-width: 2px;*/
  width: 325px;
}

.mainMenu {
  height: 200px;
  width: 150px;

  border-radius: 10px;
  color: #ffffff;
  font-size: 30px;
  font-weight: 800;
  margin: 5px;
}

.graphLegend {
  height: 7px;
  width: 15px;

  border-radius: 2px;
  /*color: #ffffff;
  font-size: 30px;
  font-weight: 800;*/
  margin: 2px;
  padding: 0px;
}

.ui-datepicker {
  width: 65px;
  height: auto;
  margin: 2px;
  padding: 1px;
  border-width: 0px;
  font-weight: bolder;
  text-align: center;
  font: 8pt Arial /*, sans-serif*/;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px #36333380;
}
.progressBarBox {
  width: 130px;
  padding-top: 0px;
  transform: translateY(10px);
}

.progressBarBoxPurple {
  width: 130px;
  padding-top: 0px;
  transform: translateY(10px);
  -info: #b31c7d;
}

.percentParagraph {
  font-size: 10px;
  font-weight: bold;

  color: white;
  text-align: right;
  padding-top: 0px;
  transform: translateY(10px);
  margin: 0px;
}

/*Paragraph styling*/
.indicateParagraph {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  width: 60px;
  font-family: arial;
  font-weight: 400;
  color: #2d5c88;
  font-size: 9px;
  padding: 0px;
  margin: 0;
  text-align: right;
}

.progress.vertical {
  position: relative;
  width: 30px;
  height: 150px;
  display: inline-block;
  margin-right: 10px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px #36333380;
}

.progress.vertical > .progress-bar {
  width: 100% !important;
  position: absolute;
  bottom: 0;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px #36333380;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  line-height: 15px;
  color: #fff;
  text-align: center;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px #36333380;
}
