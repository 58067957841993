main.container {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: 0px;
  max-width: 100%;
  /*margin-bottom: 500px;*/
}

.body {
  width: 10px;
  margin-top: 30;
  padding: 0px 0 0 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 0.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*navbar*/
.navbar {
  background-color: #777575;
  font-family: arial;
}

.navbarRibbon {
  background-color: #ece9e9;
}

body,
html {
  height: 100%;
}
.bg1 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/five-bulb-lights-1036936-scaled.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg2 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2018/09/slider1-1.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg3 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2018/09/slider13.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg4 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/Modified_brown-framed-light-bulb-745708-scaled.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg5 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/Modified_bright-buildings-clear-close-up-425202-scaled.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg6 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2018/09/slider6.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg7 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/Modified_light-painting-at-night-327509.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg8 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/white-lantern-lot-2541310-scaled.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg9 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/illuminated-cityscape-against-blue-sky-at-night-316093.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg10 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/architecture-blur-bridge-construction-416899-scaled.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}

.simplImage {
  z-index: 1;
}
/*.navLink:active {
  color: #ffffff;
  font-size: 110%;
}
.navlink active {
  color: #ffffff;
}*/
.navLink {
  color: #ffc000;
  font-family: arial;
  font-size: 13px;
  padding: 5px;
  border: 0px;
}
.navLink2 {
  font-style: bold;
  font-weight: 700;
  color: #2d5c88;
  /*background-color: #ffffff;*/
  font-family: arial;
  font-size: 13px;
  padding: 5px;
  margin: 0;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.farid_small_btn {
  width: 100px;
  padding: 10px;
}
.faridFormStyle {
  width: 300px;
  font-family: arial;
  font-weight: 750;
  color: #2d5c88;
  font-size: 0.7rem;
}

/* Table Formatting*/
.table {
  /*box-sizing: border-box;*/
  /*max-width: 400px;*/

  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 10px;
  text-align: center;
  padding: 0px;
  /*padding-bottom: 800px;
  margin-bottom: 800px;*/
  /*border: 5px solid red;*/
}

.table tr:hover {
  background-color: #f5f5f5;
}

.table thead {
  font-size: 12px;
  font-weight: 750;
  color: #199b14;
  /*border: 5px solid red;*/
}

.table td {
  text-align: center;
  /*border: 5px solid red;*/
}
.table th {
  /*padding: 0px;*/
  font-size: 10px;
  font-weight: 750;
  color: #199b14;
  /*border: 2px solid #c2bfbf;*/
}

.tableThermostat {
  /*box-sizing: border-box;
  max-width: 100%;*/
  /*width: 1200px;*/
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 10px;
  text-align: center;
  padding: 0px;
  background-color: none;
  background-clip: content-box, padding-box, border-box;
  /*padding-bottom: 800px;
  margin-bottom: 800px;*/
  /*border: 5px solid red;*/
}
.thermostatRow {
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  background-color: none;
}
.thermostatRow:hover {
  border-top: 2px solid rgb(52, 71, 155);
  border-bottom: 2px solid rgb(52, 71, 155);
  background-color: #d4d5e9;
}
.tableThermostat tr {
  border: none;
}
.tableThermostat tr:hover {
  background-color: none /*#f5f5f5*/;
}

.tableThermostat thead {
  font-size: 12px;
  font-weight: 750;
  color: #199b14;

  /*border: 5px solid red;*/
}

.tableThermostat td {
  text-align: center;
  background-color: none;

  /*border: 5px solid red;*/
}
.tableThermostat th {
  /*padding: 0px;*/
  font-size: 10px;
  font-weight: 750;
  color: #199b14;
  /* border: 2px solid #c2bfbf;*/
}
/*Icon formatting*/
.icon {
  width: 50px;
}

/*Form Formatting*/
.formLabel {
  width: 180px;
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 10px;
  padding-bottom: 0px;
}

.form-group {
  width: 200px;
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 10px;
}

.form-control2 {
  width: 200px;
  font-family: arial;
  font-weight: 550;
  color: #784feb;
  font-size: 12px;
  border: 2px solid rgb(206, 204, 204);
  border-radius: 4px;
  height: 30px;
  padding-left: 10px;
  padding-bottom: 0px;
  margin-bottom: 10px;
}

/*button*/
.basicButton {
  color: #ffffff;
  border-radius: 5px;
  background-color: #e4bb34;
  font-size: 13px;
  height: 35px;
}

.iconButton {
  color: #ffffff;
  border-radius: 0px;
  background-color: #ffffff;
  border-color: #ffffff;
  font-size: 13px;
  height: 15px;
  width: 15px;
  padding: 0;
  margin: 0;
  opacity: 1;
  border-style: none;
}

/*Paragraph styling*/
.basicParagraph {
  width: 360px;
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 12px;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-bottom: 10px;
}
.basicParagraphMap {
  width: 85px;
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 9px;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-bottom: 10px;
}

/*Real time Paragraph*/
.rtWrapper {
  background: #c8c8c8;
  width: 200px;
  margin: 20px;
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 16px;
}

.footer {
  font-family: arial;
  font-weight: 450;
  font-size: 11px;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 5px;
  width: 100%;
  background-color: #ece9e9;
  color: #2d5c88;
  text-align: center;
  z-index: 4;
}

.modal {
  height: 350px;
  font-family: arial;
  font-weight: 450;
  color: #2d5c88;
}
.modalTitle {
  width: 300px;
  font-family: arial;
  font-weight: 550;
  font-size: 10px;
  color: dodgerblue;
}
.modalTextSmall {
  font-weight: 450;
  font-size: 10px;
  color: black;
}
.modalTextLarge {
  font-size: 12px;
  text-align: Center;
}
.modalBody {
  width: 100% !important;
  margin-top: 30px;
  margin-left: 10px;
  font-family: arial;
  font-weight: 750;
  font-size: 12px;
  color: #2d5c88;
}
.modalLine {
  width: 90%;
  margin-left: 0%;
  margin-right: 5%;
  height: 1px;
  position: absolute;
  background-color: #e4bb34;
  color: #e4bb34;
  border: none;
}

.table2 {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  width: 350px; /*100%*/
  margin: 0px;
}

.table2 td {
  float: right;
  margin-left: 5px;
  margin-right: 5px;
}
.table3 {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  width: 350px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.tableTariff {
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 10px;
  text-align: center;
  padding: 10px;
  margin: 10px;
}

.tableTariff thead {
  font-size: 10px;
  font-weight: 750;
  color: rgb(224, 18, 69);
}

.tableTariff tbody {
  padding: 0px;
  max-width: 360px;
}
.tableTariff tr:hover {
  background-color: #f5f5f5;
  padding: 0px;
  max-width: 1px;
}

.tableTariff td {
  text-align: center;
  color: #2d5c88;
  margin: 0px;
  padding-right: 5px;
  padding-left: 5px;
  border: 1px solid rgb(206, 197, 197);
}

/*body { padding: 100px; background-color: #222; }*/
.led-red-on {
  margin: 0px auto;
  width: 0px;
  height: 4px;
  border-width: 0px;
  border-radius: 50%;
  background-color: rgb(250, 8, 129);
  box-shadow: rgb(252, 1, 1) 0 -1px 7px 1px, inset rgb(250, 5, 5) 0 -1px 9px,
    #f00 0 2px 12px;
}
.led-red-off {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: rgb(158, 4, 4);
  border-radius: 50%;
  /*box-shadow: rgb(34, 0, 0) 0 -1px 7px 1px, inset rgb(97, 27, 27) 0 -1px 9px,
    rgb(44, 8, 8) 0 2px 12px;*/
}

.led-yellow-on {
  margin: 0px auto;
  width: 0px;
  height: 4px;
  border-width: 0px;
  border-radius: 50%;
  background-color: rgb(248, 183, 4);
  box-shadow: rgb(248, 183, 4) 0 -1px 7px 1px, inset rgb(248, 183, 4) 0 -1px 9px,
    rgb(248, 183, 4) 0 2px 12px;
}
.led-yellow-off {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: rgb(202, 149, 3);
  border-radius: 50%;
  /*box-shadow: rgb(248, 183, 4) 0 -1px 7px 1px, inset rgb(248, 183, 4) 0 -1px 9px,
    rgb(248, 183, 4) 0 2px 12px;*/
}
.led-green-on {
  margin: 0px auto;
  width: 0px;
  height: 4px;
  border-width: 0px;
  border-radius: 50%;
  background-color: rgb(4, 248, 37);
  box-shadow: rgb(4, 248, 37) 0 -1px 7px 1px, inset rgb(63, 204, 82) 0 -1px 9px,
    rgb(4, 248, 37) 0 2px 12px;
}
.led-gray-on {
  margin: 0px auto;
  width: 0px;
  height: 4px;
  border-width: 0px;
  border-radius: 50%;
  background-color: rgb(165, 161, 161);
  box-shadow: rgb(85, 84, 84) 0 -1px 7px 1px,
    inset rgb(165, 161, 161) 0 -1px 9px, rgb(165, 161, 161) 0 2px 12px;
}
.led-green-off {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #690;
  border-radius: 50%;
  /*box-shadow: #000 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;*/
}

.label-cursor { 
  cursor: pointer;
}

div#dashboard-container-header {
  padding: 0.5em;
  font-size: 17px;
  color: dodgerblue;
  display: flex;
  border-bottom-style: none;
  border-bottom-color: darkgrey;
  height: 2em;
  font-style: italic;
  font-weight: bold;
}
#graph-area {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 250px;
  position: relative;
  left: 0px;
}
#graph-area2 {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 80px;
  /*position: relative;
  left: 0px;*/
}
#graph-area3 {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 150px;
  /*position: relative;
  left: 0px;*/
}
#walkin-graph-area {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 250px;
  position: relative;
  left: 0px;
}

#walkin-graph-area2 {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 80px;
  /*position: relative;
  left: 0px;*/
}

#thermostat-chart {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 250px;
  position: relative;
  left: 0px;
}
#walkin-chart {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 250px;
  position: relative;
  left: 0px;
}
#onTime-chart {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 90px;
  /*position: relative;
  left: 0px;*/
}
#openDoor-chart {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 90px;
  /*position: relative;
  left: 0px;*/
}

#bldgPower-chart {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%;
  height: 250px;
  position: relative;
  left: 0px;
}
#graph-area-interval {
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  max-width: 100%; /*80%*/
  height: 350px;
  margin-left: 10px; /*150px*/
}
#demand-graph {
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  max-width: 100%; /*80%*/
  height: 350px;
  margin-left: 10px; /*150px*/
}

#graph-area-interval2 {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 80%;
  height: 300px;
  margin-left: 150px;
}

#money-graph {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  max-width: 100%; /*80%*/
  height: 350px;
  margin-left: 10px; /*150px*/
  margin-bottom: 200px;
}

#gauge-area-1 {
  border-color: #000000;
  border-style: none;
  border-width: 0px;
  /*border-radius: 24px 24px 24px 24px;*/
  /*max-width: 100%;*/
  width: 350px;
  height: 400px;
  margin-left: 50%;
  transform: translateX(-175px);
  box-shadow: 50px 50px 50px 0 rgba(0, 0, 0, 0.2),
    50px 50px 50px 0 rgba(0, 0, 0, 0.2), 50px 50px 50px 0 rgba(0, 0, 0, 0.2),
    50px 50px 50px 0 rgba(15, 15, 15, 0.19);
  /* position: relative;
  left: 50%;
  margin: 0px;
  padding: 0px;*/
  z-index: 1;
}
#gauge-area-2 {
  border-color: #000000;
  border-style: none;
  border-width: 1px;
  /*border-radius: 24px 24px 24px 24px;*/
  /*max-width: 100%;*/
  width: 250px;
  height: 200px;
  margin-left: 50%;
  transform: translate(-127px, 20px);
  /*box-shadow: 50px 50px 50px 0 rgba(0, 0, 0, 0.2),
    50px 50px 50px 0 rgba(0, 0, 0, 0.2), 50px 50px 50px 0 rgba(0, 0, 0, 0.2),
    50px 50px 50px 0 rgba(15, 15, 15, 0.19);*/
  /* position: relative;
  left: 50%;
  margin: 0px;
  padding: 0px;*/
  /*z-index: 1;*/
}
/*#gauge-area-2 {
  border-color: #000000;
  border-style: solid;
  border-width: 2px;
  max-width: 50%;
  height: 200px;
  position: relative;
  left: 0px;
  margin: 0px;
  padding: 0px;
}*/

.iconsPosition {
  position: absolute;
  top: 20px;
  z-index: 10;
}

div#dashboard-container-header-text {
  flex: 0 0 75%;
}

div#dashboard-container-header-icons {
  flex: 1 1;
  text-align: right;
}

div.dash-container {
  background-color: #ffffff;
  padding-left: 0em;
  padding-right: 0em;
  padding-top: 0em;
  width: 100%;
  left: 0px;
  display: flex;
  float: left;
  position: relative;
  flex-direction: column;
}

div.dash-top-row {
  background-color: #ffffff;
  width: auto;
  display: flex;

  flex: 1 0 auto;
  flex-direction: row;
}
div.dash-graph-box-top {
  background-color: #ffffff;
  max-width: 100%;

  margin: 10px;
  flex: 1 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
div.dash-middle-row {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  height: 30em;
}

div.dash-middle-row-half-left-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 50%;
  max-width: 50%;
  margin: 0px;
  padding-left: 0em;
  padding-right: 0em;
}

div.dash-middle-row-half-right-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 50%;
  max-width: 50%;
  margin: 0px;
  padding-left: 0em;
  padding-right: 0em;
}

div.dash-bottom-row-left-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 33%;
  max-width: 33%;
}

div.dash-bottom-row-middle-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 33%;
  max-width: 34%;
}

div.dash-bottom-row-right-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 33%;
  max-width: 33%;
}

div.dash-history-box {
  background-color: white;
  margin: 10px;
  flex: 1 0 auto;
  height: 30em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div#history-bar-chart {
  width: 100%;
  height: 30em;
}

div#bills-pie-chart {
  width: 100%;
  height: 30em;
}

div.dash-connect-box {
  background-color: white;
  margin: 10px;
  flex: 1 0 auto;
  height: 30em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.dash-simpl-global-box {
  background-color: white;
  margin: 10px;
  flex: 1 0 auto;
  height: 30em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.dash-graph-box-middle {
  background-color: rgba(255, 255, 255, 0.99);
  max-width: 100%;
  margin: 10px;
  flex: 1 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 23em;
}

div.dash-bill-pie-chart-box {
  background-color: white;
  margin: 10px;
  flex: 1 0 auto;
  height: 30em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.dash-devices-box {
  background-color: white;
  margin: 10px;
  flex: 1 0 auto;
  height: 61em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
div#dash-device-row-container {
  padding-left: 15px;
  height: 7em;
  border-bottom-style: solid;
  border-bottom-color: grey;
  display: flex;
  flex-direction: row;
}

div#dash-device-row-logo {
  line-height: 6.5em;
  flex: 1 0 auto;
  min-width: 15%;
  max-width: 15%;
}

div#dash-device-row-id {
  flex: 1 0 auto;
  line-height: 4.9em;
  padding-left: 10px;
  max-height: 25%;
  font-size: 20px;
  color: grey;
}

div#dash-device-row-data {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
}

div#dash-device-column-power {
  flex: 1 0 auto;
  padding-left: 10px;
  min-width: 33%;
  max-width: 34%;
  border-left-style: solid;
  border-left-color: grey;
}

.containerBoxes {
  border-color: black;
  border-style: solid;
  border-width: 2px;
  width: 10%;
}

.containerBoxMesh {
  border-color: #ffffff;
  border-style: none;
  border-width: 2px;
  width: 10%;
  float: right;
  position: relative;
  top: 0px;
}

.containerBoxNumbers {
  /* border-color: #ffffff;
  border-style: none;
  border-width: 2px;*/
  width: 10%;
  float: right;
  position: relative;
  top: -450px;
  right: -150px;
}

.containerGraph {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  width: 100%;
  height: 600px;
  margin: 0;
  padding: 0;
}

.orange-progress-bar {
  background-color: #8a0d4b !important;
}

.progressBar1 {
  width: 480px;
  font-size: 11px;
  font-weight: bold;
  transform: rotate(270deg) translateX(310px) translateY(-220px);
  color: white;
  text-align: center;
  padding-top: 0px;
}
.progressBar2 {
  width: 480px;
  font-size: 11px;
  font-weight: bold;
  transform: rotate(270deg) translateX(325px) translateY(-195px);
  color: white;
  text-align: center;
  padding-left: 0px;

  padding-top: 0px;
}
.progressBar3 {
  width: 480px;
  font-size: 11px;
  font-weight: bold;
  transform: rotate(270deg) translateX(340px) translateY(-165px);
  color: white;
  text-align: center;
  padding-left: 0px;
  padding-top: 0px;
}
.progressBarPowerStack {
  width: 480px;
  font-size: 11px;
  font-weight: bold;
  transform: rotate(270deg) translateX(340px) translateY(-165px);
  color: purple;
  text-align: center;
  padding-left: 0px;
  padding-top: 0px;
}
.systemClock {
  border-radius: 5px;
  width: 310px;
  margin-left: 50%;
  transform: translateX(-155px);
  color: #ffc000;
  background-color: #777575;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.systemClockNode {
  border-radius: 5px;
  width: 1150px;
  margin-left: 25px;
  /*transform: translateX(-30px);*/
  color: #ffc000;
  background-color: #777575;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.containerSimplView {
  /*border-color: #000000;
  border-style: solid;
  border-width: 2px;*/
  width: 325px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}
.containerSimplNodeVu {
  /*border-color: #000000;
  border-style: solid;
  border-width: 2px;*/
  width: 1200px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}
.mainMenuIcons {
  border-color: #f7f1f1;
  background-color: #f7f1f1;
  border-style: solid;
  border-width: 0px;
  border-radius: 5px;
  padding-top: 5px;
  width: 325px;
}
.mainMenuNodesIcons {
  border-color: #f7f1f1;
  background-color: #f7f1f1;
  border-style: solid;
  border-width: 0px;
  border-radius: 5px;
  padding-top: 5px;
  width: 1200px;
}
.mainMenuContainer {
  /*border-color: #ffffff;
  border-style: solid;
  border-width: 2px;*/
  width: 325px;
}

.mainMenu {
  height: 200px;
  width: 150px;

  border-radius: 10px;
  color: #ffffff;
  font-size: 30px;
  font-weight: 800;
  margin: 5px;
}

.graphLegend {
  height: 7px;
  width: 15px;

  border-radius: 2px;
  /*color: #ffffff;
  font-size: 30px;
  font-weight: 800;*/
  margin: 2px;
  padding: 0px;
}

.ui-datepicker {
  width: 65px;
  height: auto;
  margin: 2px;
  padding: 1px;
  border-width: 0px;
  font-weight: bolder;
  text-align: center;
  font: 8pt Arial /*, sans-serif*/;
  box-shadow: 0px 0px 10px 0px #36333380;
}
.progressBarBox {
  width: 130px;
  padding-top: 0px;
  transform: translateY(10px);
}

.progressBarBoxPurple {
  width: 130px;
  padding-top: 0px;
  transform: translateY(10px);
  -info: #b31c7d;
}

.percentParagraph {
  font-size: 10px;
  font-weight: bold;

  color: white;
  text-align: right;
  padding-top: 0px;
  transform: translateY(10px);
  margin: 0px;
}

/*Paragraph styling*/
.indicateParagraph {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  width: 60px;
  font-family: arial;
  font-weight: 400;
  color: #2d5c88;
  font-size: 9px;
  padding: 0px;
  margin: 0;
  text-align: right;
}

.progress.vertical {
  position: relative;
  width: 30px;
  height: 150px;
  display: inline-block;
  margin-right: 10px;
  box-shadow: 0px 0px 10px 0px #36333380;
}

.progress.vertical > .progress-bar {
  width: 100% !important;
  position: absolute;
  bottom: 0;
  box-shadow: 0px 0px 10px 0px #36333380;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  line-height: 15px;
  color: #fff;
  text-align: center;
  box-shadow: 0px 0px 10px 0px #36333380;
}

.ExcelTable2007 {
  border: 1px solid #ffffff;
  border-width: 1px 0px 0px 1px;
  font-size: 10px;
  font-family: arial;
  font-weight: 100;
  border-spacing: 0px;
  border-collapse: collapse;
}

.ExcelTable2007 th {
  /*background-image: url(excel-2007-header-bg.gif);*/
  background-repeat: repeat-x;
  font-weight: normal;
  font-size: 12px;
  border: 1px solid #ffffff;
  border-width: 0px 1px 1px 0px;
  height: 17px;
  text-align: center;
  color: white;
}

.ExcelTable2007 td {
  border: 0px;
  width: 20px;
  background-color: white;
  padding: 0px 4px 0px 2px;
  border: 1px solid #d0d7e5;
  border-width: 0px 1px 1px 0px;
  border: 1px solid #ffffff;
  text-align: center;
}

.ExcelTable2007 td b {
  border: 0px;
  background-color: rgb(133, 101, 101);
  font-weight: bold;
  border: 1px solid #ffffff;
  color: white;
}

.ExcelTable2007 td.heading {
  background-color: #ffffff;
  text-align: center;
  border: 1px solid #ffffff;
  border-width: 0px 1px 1px 0px;
  color: white;
}

.ExcelTable2007 th.heading {
  /*background-image: url(excel-2007-header-left.gif);*/
  background-repeat: none;
  border: 1px solid #ffffff;
}

.inputTariff {
  background-color: #395181;
  width: 250px;
  border-radius: 10px;
  color: #ffffff;
  font-family: arial;
  font-size: 12px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

