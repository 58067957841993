main.container {
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  border: 0px;
  max-width: 100%;
  /*margin-bottom: 500px;*/
}

.body {
  width: 10px;
  margin-top: 30;
  padding: 0px 0 0 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 0.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*navbar*/
.navbar {
  background-color: #777575;
  font-family: arial;
}

.navbarRibbon {
  background-color: #ece9e9;
}

body,
html {
  height: 100%;
}
.bg1 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/five-bulb-lights-1036936-scaled.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg2 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2018/09/slider1-1.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg3 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2018/09/slider13.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg4 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/Modified_brown-framed-light-bulb-745708-scaled.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg5 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/Modified_bright-buildings-clear-close-up-425202-scaled.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg6 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2018/09/slider6.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg7 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/Modified_light-painting-at-night-327509.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg8 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/white-lantern-lot-2541310-scaled.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg9 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/illuminated-cityscape-against-blue-sky-at-night-316093.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}
.bg10 {
  background-image: url("https://simplglobal.com/wp-content/uploads/2020/03/architecture-blur-bridge-construction-416899-scaled.jpg");
  height: 150vh;
  background-position: fixed; /*was fixed before...just trying things out for backgroundSlideshow*/
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: -2;
}

.simplImage {
  z-index: 1;
}
/*.navLink:active {
  color: #ffffff;
  font-size: 110%;
}
.navlink active {
  color: #ffffff;
}*/
.navLink {
  color: #ffc000;
  font-family: arial;
  font-size: 13px;
  padding: 5px;
  border: 0px;
}
.navLink2 {
  font-style: bold;
  font-weight: 700;
  color: #2d5c88;
  /*background-color: #ffffff;*/
  font-family: arial;
  font-size: 13px;
  padding: 5px;
  margin: 0;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.farid_small_btn {
  width: 100px;
  padding: 10px;
}
.faridFormStyle {
  width: 300px;
  font-family: arial;
  font-weight: 750;
  color: #2d5c88;
  font-size: 0.7rem;
}

/* Table Formatting*/
.table {
  /*box-sizing: border-box;*/
  /*max-width: 400px;*/

  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 10px;
  text-align: center;
  padding: 0px;
  /*padding-bottom: 800px;
  margin-bottom: 800px;*/
  /*border: 5px solid red;*/
}

.table tr:hover {
  background-color: #f5f5f5;
}

.table thead {
  font-size: 12px;
  font-weight: 750;
  color: #199b14;
  /*border: 5px solid red;*/
}

.table td {
  text-align: center;
  /*border: 5px solid red;*/
}
.table th {
  /*padding: 0px;*/
  font-size: 10px;
  font-weight: 750;
  color: #199b14;
  /*border: 2px solid #c2bfbf;*/
}

.tableThermostat {
  /*box-sizing: border-box;
  max-width: 100%;*/
  /*width: 1200px;*/
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 10px;
  text-align: center;
  padding: 0px;
  background-color: none;
  background-clip: content-box, padding-box, border-box;
  /*padding-bottom: 800px;
  margin-bottom: 800px;*/
  /*border: 5px solid red;*/
}
.thermostatRow {
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  background-color: none;
}
.thermostatRow:hover {
  border-top: 2px solid rgb(52, 71, 155);
  border-bottom: 2px solid rgb(52, 71, 155);
  background-color: #d4d5e9;
}
.tableThermostat tr {
  border: none;
}
.tableThermostat tr:hover {
  background-color: none /*#f5f5f5*/;
}

.tableThermostat thead {
  font-size: 12px;
  font-weight: 750;
  color: #199b14;

  /*border: 5px solid red;*/
}

.tableThermostat td {
  text-align: center;
  background-color: none;

  /*border: 5px solid red;*/
}
.tableThermostat th {
  /*padding: 0px;*/
  font-size: 10px;
  font-weight: 750;
  color: #199b14;
  /* border: 2px solid #c2bfbf;*/
}
/*Icon formatting*/
.icon {
  width: 50px;
}

/*Form Formatting*/
.formLabel {
  width: 180px;
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 10px;
  padding-bottom: 0px;
}

.form-group {
  width: 200px;
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 10px;
}

.form-control2 {
  width: 200px;
  font-family: arial;
  font-weight: 550;
  color: #784feb;
  font-size: 12px;
  border: 2px solid rgb(206, 204, 204);
  border-radius: 4px;
  height: 30px;
  padding-left: 10px;
  padding-bottom: 0px;
  margin-bottom: 10px;
}

/*button*/
.basicButton {
  color: #ffffff;
  border-radius: 5px;
  background-color: #e4bb34;
  font-size: 13px;
  height: 35px;
}

.iconButton {
  color: #ffffff;
  border-radius: 0px;
  background-color: #ffffff;
  border-color: #ffffff;
  font-size: 13px;
  height: 15px;
  width: 15px;
  padding: 0;
  margin: 0;
  opacity: 1;
  border-style: none;
}

/*Paragraph styling*/
.basicParagraph {
  width: 360px;
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 12px;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-bottom: 10px;
}
.basicParagraphMap {
  width: 85px;
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 9px;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-bottom: 10px;
}

/*Real time Paragraph*/
.rtWrapper {
  background: #c8c8c8;
  width: 200px;
  margin: 20px;
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 16px;
}

.footer {
  font-family: arial;
  font-weight: 450;
  font-size: 11px;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 5px;
  width: 100%;
  background-color: #ece9e9;
  color: #2d5c88;
  text-align: center;
  z-index: 4;
}

.modal {
  height: 350px;
  font-family: arial;
  font-weight: 450;
  color: #2d5c88;
}
.modalTitle {
  width: 300px;
  font-family: arial;
  font-weight: 550;
  font-size: 10px;
  color: dodgerblue;
}
.modalTextSmall {
  font-weight: 450;
  font-size: 10px;
  color: black;
}
.modalTextLarge {
  font-size: 12px;
  text-align: Center;
}
.modalBody {
  width: 100% !important;
  margin-top: 30px;
  margin-left: 10px;
  font-family: arial;
  font-weight: 750;
  font-size: 12px;
  color: #2d5c88;
}
.modalLine {
  width: 90%;
  margin-left: 0%;
  margin-right: 5%;
  height: 1px;
  position: absolute;
  background-color: #e4bb34;
  color: #e4bb34;
  border: none;
}

.table2 {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  width: 350px; /*100%*/
  margin: 0px;
}

.table2 td {
  float: right;
  margin-left: 5px;
  margin-right: 5px;
}
.table3 {
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px;
  width: 350px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.tableTariff {
  font-family: arial;
  font-weight: 550;
  color: #2d5c88;
  font-size: 10px;
  text-align: center;
  padding: 10px;
  margin: 10px;
}

.tableTariff thead {
  font-size: 10px;
  font-weight: 750;
  color: rgb(224, 18, 69);
}

.tableTariff tbody {
  padding: 0px;
  max-width: 360px;
}
.tableTariff tr:hover {
  background-color: #f5f5f5;
  padding: 0px;
  max-width: 1px;
}

.tableTariff td {
  text-align: center;
  color: #2d5c88;
  margin: 0px;
  padding-right: 5px;
  padding-left: 5px;
  border: 1px solid rgb(206, 197, 197);
}

/*body { padding: 100px; background-color: #222; }*/
.led-red-on {
  margin: 0px auto;
  width: 0px;
  height: 4px;
  border-width: 0px;
  border-radius: 50%;
  background-color: rgb(250, 8, 129);
  box-shadow: rgb(252, 1, 1) 0 -1px 7px 1px, inset rgb(250, 5, 5) 0 -1px 9px,
    #f00 0 2px 12px;
}
.led-red-off {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: rgb(158, 4, 4);
  border-radius: 50%;
  /*box-shadow: rgb(34, 0, 0) 0 -1px 7px 1px, inset rgb(97, 27, 27) 0 -1px 9px,
    rgb(44, 8, 8) 0 2px 12px;*/
}

.led-yellow-on {
  margin: 0px auto;
  width: 0px;
  height: 4px;
  border-width: 0px;
  border-radius: 50%;
  background-color: rgb(248, 183, 4);
  box-shadow: rgb(248, 183, 4) 0 -1px 7px 1px, inset rgb(248, 183, 4) 0 -1px 9px,
    rgb(248, 183, 4) 0 2px 12px;
}
.led-yellow-off {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: rgb(202, 149, 3);
  border-radius: 50%;
  /*box-shadow: rgb(248, 183, 4) 0 -1px 7px 1px, inset rgb(248, 183, 4) 0 -1px 9px,
    rgb(248, 183, 4) 0 2px 12px;*/
}
.led-green-on {
  margin: 0px auto;
  width: 0px;
  height: 4px;
  border-width: 0px;
  border-radius: 50%;
  background-color: rgb(4, 248, 37);
  box-shadow: rgb(4, 248, 37) 0 -1px 7px 1px, inset rgb(63, 204, 82) 0 -1px 9px,
    rgb(4, 248, 37) 0 2px 12px;
}
.led-gray-on {
  margin: 0px auto;
  width: 0px;
  height: 4px;
  border-width: 0px;
  border-radius: 50%;
  background-color: rgb(165, 161, 161);
  box-shadow: rgb(85, 84, 84) 0 -1px 7px 1px,
    inset rgb(165, 161, 161) 0 -1px 9px, rgb(165, 161, 161) 0 2px 12px;
}
.led-green-off {
  margin: 20px auto;
  width: 12px;
  height: 12px;
  background-color: #690;
  border-radius: 50%;
  /*box-shadow: #000 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;*/
}

.label-cursor { 
  cursor: pointer;
}
